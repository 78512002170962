<!--  -->
<template>
  <div class="add_remark_Modal">
    <Modal v-model="is_show" title="邀约面试" @on-visible-change="modalChange">
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="120">
        <FormItem label="面试职位" prop="job_name">
          <Input v-model="formValidate.job_name" readonly placeholder="面试职位"></Input>
        </FormItem>

        <FormItem label="面试方式" prop="method">
          <Select v-model="formValidate.method" placeholder="面试方式">
            <Option value="到企业面试">到企业面试</Option>
            <Option value="视频面试">视频面试</Option>
            <Option value="其他">其他</Option>
          </Select>
        </FormItem>

        <FormItem label="面试日期时间" prop="date">
          <DatePicker type="datetime" format="yyyy-MM-dd HH:mm:ss" placeholder="面试日期时间" style="width: 200px"
            v-model="formValidate.date"></DatePicker>
        </FormItem>

        <FormItem label="面试地点">
          <Input v-model="formValidate.address" placeholder="面试地点"></Input>
        </FormItem>

        <FormItem label="联系方式" prop="contact">
          <Input v-model="formValidate.contact" placeholder="联系方式"></Input>
        </FormItem>

        <FormItem label="注意事项">
          <Input v-model="formValidate.matters" type="textarea" :autosize="{ minRows: 3 }" placeholder="注意事项"></Input>
        </FormItem>

        <p style="text-align: center; color: red">标红星项为必填项，请按要求填写！</p>
      </Form>

      <div slot="footer">
        <Button type="text" @click="is_show = false">取消</Button>
        <Button :loading="but_loading" type="primary" @click="handleSubmit('formValidate')">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      but_loading: false,
      is_show: false,
      formValidate: {
        job_name: "",
        _resumeId: "",
        userId: "",
        position: "",
        date: "",
        method: "",
        contact: "",
        address: "",
        matters: "",
      },
      ruleValidate: {
        job_name: [{ required: true, message: "面试职位", trigger: "blur" }],
        method: [{ required: true, message: "请选择面试方式", trigger: "change" }],
        date: [
          { required: true, type: "date", message: "请选择面试时间", trigger: "change" },
        ],
        contact: [{ required: true, message: "面试职位", trigger: "blur" }],
      },
    };
  },
  methods: {
    modalChange(bool) {
      if (bool) {
        this.formValidate.address = localStorage.getItem("invite_address");
        this.formValidate.contact = localStorage.getItem("invite_contact");
        this.formValidate.matters = localStorage.getItem("invite_matters");
      } else {
        this.formValidate.method = "";
        this.formValidate.date = "";
        this.$refs["formValidate"].resetFields();
      }
    },
    //面试邀约时间改变
    Date_change(val) {
      this.formValidate.date = val
    },
    //提交
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.but_loading = true;
          console.log(this.formValidate);
          //深拷贝 不改变原来对象
          let newdata = JSON.parse(JSON.stringify(this.formValidate));
          newdata.date = this.dayjs(newdata.date).format("YYYY-MM-DD HH:mm:ss");
          console.log(newdata);
          this.app("enterpriseOperators", "sendInterview", newdata).then((res) => {
            console.log(res);
            this.$Message.warning(res.message);
            if (res.code == 200) {
              //保存信息为下次默认
              localStorage.setItem("invite_address", newdata.address); //邀约地点
              localStorage.setItem("invite_contact", newdata.contact); //联系方式
              localStorage.setItem("invite_matters", newdata.matters); //注意事项
              this.but_loading = false;
              this.is_show = false;
              this.$emit("change");
              setTimeout(() => {
                location.reload();
              }, 3000);
            }
          });
        } else {
          this.$Message.error("请填写完整信息");
        }
      });
    },
  },
  created() { },
};
</script>

<style lang="less" scoped>
/deep/.ivu-modal {
  width: 530px !important;
}
</style>
