<template>
  <div class="user_maindate">
    <Spin size="large" fix v-if="spinShow"></Spin>
    <div class="header">
      <div class="header_left">
        <Button size="small" type="primary" @click="$router.back()">
          <Icon type="ios-arrow-back" />
          返回
        </Button>
        <Divider type="vertical" />
        <p>{{ title_name }}</p>
      </div>
      <!-- 预约面试和下载简历 -->
      <div class="main-top-btn">
        <!-- 收藏 -->
        <div class="main-top-btn_in">
          <img src="../../../../assets/images/collected.png" class="collected" @click="collecteds" alt=""
            v-if="enterpriseResume.collect" />
          <img src="../../../../assets/images/collect.png" class="collect" @click="collects" alt="" v-else />
          <div style="color: #352a04; font-size: 16px">收藏</div>
        </div>
        <!-- 邀约面试 -->
        <div :class="enterpriseResume.status == 150 ? 'interviewed' : 'interview'" style="cursor: pointer"
          @click="intervieweds">
          {{ enterpriseResume.status == 150 ? "已约面" : "邀约面试" }}
        </div>
        <Button type="warning" @click="download">下载简历</Button>
      </div>
    </div>

    <section ref="imageWrapper">
      <!-- 基本信息 -->
      <div class="title_style">
        <Row>
          <Col span="19">
          <div class="title">
            <div></div>
            <p>基本信息</p>
          </div>
          <ul>

            <li style="margin-bottom:10px">
              <p class="name">姓名：{{ personal.name }}</p>
              <p class="name">投递职位：{{ enterpriseResume.position.name }}</p>
            </li>
            <li>
              <p>投递日期：{{ dayjs($route.query.date * 1).format("YYYY-MM-DD") }}</p>
              <p>性别：{{ personal.sex == 1 ? "男" : "女" }}</p>
              <p>年龄：{{ jsGetAge(personal.birthDate) }}</p>
            </li>
            <li>

              <p>工作经验：{{ working_age(personal.workedDate) }}年</p>
              <p>学历：{{ personal.education }}</p>
              <p>联系电话：<span style="color: #ff8300">{{ firm_data.type && firm_data.type != 1 || enterpriseResume.status
                ==
                150 ? personal.phone : personal.phone.replace(personal.phone.substring(3, 7), "****") }}</span></p>
            </li>
            <li>
              <p>户籍：{{ personal.native.join("-") }}</p>
              <p>现居住地：{{ personal.address.join("-") }}</p>
            </li>

            <p style="margin-bottom:10px">技能特长：{{ personal.skill }}</p>

            <p>自我简介：{{ personal.profiles }}</p>

          </ul>
          </Col>

          <Col span="5" style="text-align:center;padding-top:5px;">
          <img src="@/assets/images/resume_logo.png" alt="">
          </Col>
        </Row>
      </div>

      <!-- 全职求职意向 -->
      <div class="title_style" v-if="type == 1">
        <Row>
          <Col span="19">
          <div class="title">
            <div></div>
            <p>全职求职意向</p>
          </div>
          <ul>
            <li>
              <p>薪资范围：{{ personal.fullTimeIntention.pay + "元/月" }}</p>
              <p>意向工作地：{{ personal.fullTimeIntention.workplace.join("-") }}</p>
            </li>
            <li>
              <p>求职意向：{{ personal.fullTimeIntention.jobIntention }}</p>
              <p>意向行业：{{ personal.fullTimeIntention.trade }}</p>
            </li>
          </ul>
          </Col>
          <Col span="5">
          </Col>
        </Row>
      </div>

      <!-- 小时工求职意向 -->
      <div class="title_style" v-else>
        <Row>
          <Col span="19">
          <div class="title">
            <div></div>
            <p>小时工求职意向</p>
          </div>
          <ul>
            <li>
              <p>薪资范围：{{ hourSalary }}</p>
              <p>意向工作地：{{ hourArea }}</p>
            </li>
            <li>
              <p>结算方式：{{ hourClearform }}</p>
              <p>空闲时间段：{{ hourWorkTime }}</p>
            </li>
          </ul>
          </Col>
          <Col span="5">
          </Col>
        </Row>
      </div>

      <!-- 工作经历 -->
      <div class="title_style">
        <Row>
          <Col span="19">
          <div class="title">
            <div></div>
            <p>工作经历</p>
          </div>
          <div v-if="personal.workRecords.length">
            <div v-for="(item, index) in personal.workRecords" :key="item._id" style="margin-bottom:20px">
              <ul>
                <li>
                  <p>公司名称：{{ item.company }}</p>
                  <p>职位名称：{{ item.position }}</p>
                  <p>工作年限：{{ item.entryDate }} - {{ item.leaveDate }}</p>
                </li>
                <p>工作内容：{{ item.work_description }}</p>
              </ul>
            </div>
          </div>
          <span v-else>暂无</span>
          </Col>
          <Col span="5">
          </Col>
        </Row>
      </div>

      <!-- 教育经历 -->
      <div class="title_style">
        <Row>
          <Col span="19">
          <div class="title">
            <div></div>
            <p>教育经历</p>
          </div>
          <div v-if="personal.eduRecords.length">
            <div v-for="(item, index) in personal.eduRecords" :key="item._id" style="margin-bottom:10px">
              <ul>
                <li>
                  <p>学校名称：{{ item.school }}</p>
                  <p>最高学历：{{ item.education }}</p>
                  <p>专业名称：{{ item.specialities }}</p>
                </li>
              </ul>
            </div>
          </div>
          <span v-else>暂无</span>
          </Col>
          <Col span="5">
          </Col>
        </Row>
      </div>
      <!-- 技能证书 -->
      <div class="title_style">
        <div class="title">
          <div></div>
          <p>技能证书</p>
        </div>
        <span v-if="personal.skillsCertificate">{{ personal.skillsCertificate ?
          personal.skillsCertificate[0].certificates : "" }}</span>
        <span v-else>暂无</span>
        <p style="text-align:right">此简历由黄埔人才网提供</p>
      </div>

    </section>

    <InviteModal ref="InviteModal" @change="" />

    <!-- 开通权限弹窗 -->
    <Modal v-model="modal2" title="联系客服开通权限">
      <div>
        <div class="window_main">
          <div class="window_top">请联系黄埔人才网客服开通权限</div>
          <div class="window_top" style="margin-bottom: 32px">
            （请致电<span style="color: #ff8300">18922472098</span>或<span style="color: #ff8300">扫码下方二维码</span>）
          </div>
          <div class="window_text">
            <img src="../../../../assets/images/kefu_left.png" style="width: 104px; height: 104px" alt="" />
          </div>
        </div>
      </div>
    </Modal>

  </div>
</template>

<script>
import html2canvas from "html2canvas";
import InviteModal from "./invite_modal.vue";
import JsPDF from 'jspdf';
export default {
  data() {
    return {
      type: 1,
      title_name: "",
      mobile: localStorage.getItem("firm_mobile"),
      enterpriseId: localStorage.getItem("firm_enterpriseId"),

      personal: {
        name: "",
        phone: "",
        native: [],
        address: [],
        fullTimeIntention: {
          workplace: [],
          jobIntention: [],
          trade: [],
          pay: "",
        },
        skillsCertificate: [{ certificates: [] }],
        workRecords: [],
        eduRecords: []
      },
      enterpriseResume: {
        position: {
          name: "",
          // status: 0,
        },
      },

      //小时工
      hourPosition: "",
      hourWorkTime: "",
      hourSalary: "",
      hourArea: "",
      hourClearform: "",

      // 邀约面试
      itr_name: "",
      itr_type: "",
      itr_date: "",
      itr_time: "",
      itr_address: "",
      itr_conect: "",
      itr_description: "",
      modal1: false,

      modal2: false,
    };
  },
  props: ["firm_data"],
  components: {
    InviteModal,
  },
  computed: {
    //参加工作时间获取工作经验年龄
    working_age() {
      return (data) => {
        var workedDate = data[0] + data[1] + data[2] + data[3];
        var y = new Date().getFullYear()
        return y - workedDate
      }
    }
  },
  methods: {
    // ************************获取简历
    get_info() {
      this.spinShow = true
      this.app("enterpriseOperators", "viewResume", {
        _id: this.$route.query.id,
        mobile: this.mobile,
      }).then((result) => {
        console.log(result);
        this.spinShow = false

        this.personal = result.personal;
        this.enterpriseResume = result.enterpriseResume;

        // 小时工求职意向
        if (this.personal.temporaryIntention) {
          this.hourPosition = this.personal.temporaryIntention.position;
          this.hourWorkTime =
            this.personal.temporaryIntention.workDate_start +
            "至" +
            this.personal.temporaryIntention.workDate_end +
            " " +
            this.personal.temporaryIntention.workTime;
          this.hourSalary = this.personal.temporaryIntention.salary + "元/小时";
          if (this.personal.temporaryIntention.area.length > 0) {
            this.hourArea =
              this.personal.temporaryIntention.area[0] +
              "-" +
              this.personal.temporaryIntention.area[1] +
              "-" +
              this.personal.temporaryIntention.area[2];
          }
          this.hourClearform = this.personal.temporaryIntention.clearFrom;
        }
      });
    },

    // 下载图片
    download() {
      // 记录下载数量
      // if (
      //   this.enterpriseResume.enterpriseType == 2 ||
      //   this.enterpriseResume.enterpriseType == 3
      // ) {
      this.app("enterpriseOperators", "downloadResume", {
        mobile: this.mobile,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          console.log(html2canvas);
          /** 生成图片*/
          html2canvas(this.$refs.imageWrapper).then((canvas) => {
            console.log(canvas.width);
            console.log(canvas.height);
            let dataURL = canvas.toDataURL("image/png");


            //方向默认竖直，尺寸ponits，格式a4[595.28,841.89]
            var pdf = new JsPDF('', 'pt', 'a4');

            //addImage后两个参数控制添加图片的尺寸，此处将页面高度按照a4纸宽高比列进行压缩
            pdf.addImage(dataURL, 'JPEG', 0, 0, 595.28, 592.28 / canvas.width * canvas.height);
            // pdf.addImage(dataURL, 'JPEG', 0, 0, 595.28, 592.28);

            //保存到pdf，名字是stone
            pdf.save(this.personal.name + '简历详情');

            // if (dataURL !== "") {
            //   /** 下载图片*/
            //   let alink = document.createElement("a");
            //   alink.href = dataURL;
            //   alink.download = "downloadName.png";
            //   alink.click();
            // }
          });
        } else {
          this.modal2 = true;
        }
      });

      // } else {
      //   this.modal2 = true;
      // }
    },

    // *************************收藏简历
    collects() {
      this.app("enterpriseOperators", "collectResume", {
        resume_id: this.$route.query.id,
        ent_id: this.enterpriseId,
        user_id: this.personal.userId,
        type: this.type,
      }).then((queryRes) => {
        console.log(queryRes);
        if (queryRes.code == 200) {
          this.$Message.info("收藏简历成功");

          this.enterpriseResume.collect = true;
        } else {
          this.$Message.info(queryRes.message);
        }
      });
    },

    // 取消收藏简历
    collecteds() {
      this.app("enterpriseOperators", "deleteCollectResume", {
        collect_id: this.enterpriseResume.collectId,
      }).then((queryRes) => {
        this.$Message.info("取消收藏简历成功");

        this.enterpriseResume.collect = false;
      });
    },

    // 邀约面试
    intervieweds() {
      if (this.enterpriseResume.status == 150) {
        this.$Message.info("已约面，请勿重复邀约");
      } else {
        // this.modal1 = true
        this.$refs.InviteModal.is_show = true;
        this.$refs.InviteModal.formValidate.job_name = this.enterpriseResume.position.name;
        this.$refs.InviteModal.formValidate._resumeId = this.$route.query.id;
        this.$refs.InviteModal.formValidate.userId = this.personal.userId;
        this.$refs.InviteModal.formValidate.position = this.enterpriseResume.position._id;
      }
    },

    // 日期选择
    getDate(date) {
      console.log(date);
      this.itr_date = date;
    },

    // ************************************邀约面试
    personOk() {
      if (
        this.itr_type == "" ||
        this.itr_date == "" ||
        this.itr_time == "" ||
        this.itr_conect == ""
      ) {
        this.$Message.info("红点为必填项");
      } else {
        this.app("enterpriseOperators", "sendInterview", {
          _resumeId: this.$route.query.id,
          userId: this.personal.userId,
          position: this.enterpriseResume.position._id,
          date: date_time,
          method: this.itr_type,
          contact: this.itr_conect,
          address: this.itr_address,
          matters: this.itr_description,
        }).then((queryRes) => {
          console.log(queryRes);

          this.$Message.success("邀约面试成功");
          this.modal1 = false;
        });
      }
    },
  },

  created() {
    this.type = this.$route.query.type;
    if (this.type == 1) {
      this.title_name = "全职简历详情";
    } else {
      this.title_name = "小时工简历详情";
    }

    this.get_info();
  },
};
</script>

<style lang="less" scoped>
@import "./resume_manage_detail.less";
</style>
